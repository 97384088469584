<template>
  <div>
    <compTermini v-if="this.showTermini" @eventClose="chiudiTerminiServizio" />
    <compMarketing
      v-else-if="this.showMarketing"
      @eventClose="chiudiMarketingPrivacy"
    />
    <div v-else class="bgImage fontGigya formGigya flex">
      <div class="panelLogin">
        <div class="paddingRegistration">
          <!-- DIV PER LOGO -->
          <div id="panelLogo">
            <div id="panelLogoBrand" class="panelImg">
              <img
                src="@/assets/logo_age_go.png"
                class="mt5 mb5 resizedBrand"
              />
            </div>
          </div>

          <div id="toogglebtn" class="flex padding5px togglePanel mb20">
            <div
              class="toggleDefault pointer"
              @click="setCompToRender('compLoginGigya')"
            >
              <span>{{ gigyaAccedi }}</span>
            </div>
            <div class="toggleActive">{{ gigyaRegistrati }}</div>
          </div>

          <div class="panelLoginRow">
            <label for="name_in" class="bold fa-1x pull-left mt10"
              >{{ gigyaNome }} <i class="fa fa-asterisk red asterisk"></i
            ></label>
            <input
              maxlength="100"
              class="inputStyle textIndent h30-nomargin"
              :placeholder="gigyaNomePlaceholder"
              v-model="nome"
              name="name_in"
              id="name_in"
              type="text"
              autocomplete="off"
            />
          </div>
          <div class="panelLoginRow">
            <label for="surname_in" class="bold fa-1x pull-left mt10"
              >{{ gigyaCognome }} <i class="fa fa-asterisk red asterisk"></i
            ></label>
            <input
              maxlength="100"
              :placeholder="gigyaCognomePlaceholder"
              v-model="cognome"
              class="inputStyle textIndent h30-nomargin"
              name="surname_in"
              id="surname_in"
              type="text"
              autocomplete="off"
            />
          </div>
          <div class="panelLoginRow">
            <label for="email_in" class="bold fa-1x pull-left mt10"
              >{{ gigyaEmail }} <i class="fa fa-asterisk red asterisk"></i
            ></label>
            <input
              maxlength="100"
              class="inputStyle textIndent h30-nomargin"
              :placeholder="gigyaEmailPlaceholder"
              v-model="mail"
              name="email_in"
              id="email_in"
              type="text"
              @keyup="checkMail"
              autocomplete="off"
            />
          </div>
          <div class="panelLoginRow">
            <div id="valid_email" class="hidden">
              {{ this.getLabel("gigya_valid_email") }}
            </div>
            <div id="invalid_email" class="hidden">
              {{ this.getLabel("gigya_invalid_email") }}
            </div>
            <div id="email_in_use" class="hidden">
              {{ this.getLabel("gigya_email_in_use") }}
            </div>
          </div>

          <div class="panelLoginRow">
            <label for="mobile_in" class="bold fa-1x pull-left mt10"
              >{{ gigyaCell }} <i class="fa fa-asterisk red asterisk"></i
            ></label>
            <input
              maxlength="100"
              class="inputStyle textIndent h30-nomargin"
              :placeholder="gigyaCellPlaceholder"
              v-model="cellulare"
              name="mobile_in"
              id="mobile_in"
              type="text"
              autocomplete="off"
            />
          </div>

          <div class="panelLoginRow">
            <label for="user_password_in" class="bold fa-1x pull-left mt10"
              >{{ labelPassword }} <i class="fa fa-asterisk red asterisk"></i
            ></label>
            <div class="relative">
              <input
                maxlength="100"
                class="inputStyle textIndent h30-nomargin"
                :placeholder="gigyaPasswordPlaceholder"
                v-model="password"
                name="user_password_in"
                id="user_password_in"
                :type="inputType"
                autocomplete="off"
                @keyup="checkPassword"
              />
              <span @click="showPassword" class="showPasswordRegistration">
                <font-awesome-icon
                  :icon="['fas', eyeIcon]"
                  :style="{ color: 'black' }"
                />
              </span>
            </div>

            <div class="panelLoginRow">
              <div id="invalid_pwd" class="hidden">
                {{ gigyaInvalidPwd }}
              </div>
            </div>
          </div>

          <div class="panelLoginRow">
            <label
              for="user_confirm_password_in"
              class="bold fa-1x pull-left mt10"
              >{{ gigyaConfirmPassword }}
              <i class="fa fa-asterisk red asterisk"></i>
            </label>
            <div class="relative">
              <input
                maxlength="100"
                class="inputStyle textIndent h30-nomargin"
                :placeholder="gigyaConfirmPasswordPlaceholder"
                v-model="cPassword"
                :type="inputTypeRegistration"
                name="user_confirm_password_in"
                id="user_confirm_password_in"
                autocomplete="off"
              />
              <span
                @click="showConfirmPassword"
                class="showPasswordRegistration"
              >
                <font-awesome-icon
                  :icon="['fas', eyeIconRegistration]"
                  :style="{ color: 'black' }"
                />
              </span>
            </div>
          </div>

          <div class="panelLoginRow">
            <label for="country_in" class="bold fa-1x pull-left mt10">{{
              labelNazione
            }}</label>
            <select
              name="country_in"
              id="country_in"
              v-model="nazione"
              class="inputStyle textIndent h30-nomargin"
              onchange="translatePage();"
            ></select>
          </div>

          <div class="mt10 textStart flex alignStart">
            <input
              name="terms_in"
              v-model="checkTermini"
              id="terms_in"
              type="checkbox"
              @click="changeStatus"
            />
            <div
              id="terms_lbl"
              for="terms_in"
              class="disclaimerCheckBox"
              v-html="this.gigyaTerms"
            ></div>
          </div>
          <div class="textStart flex alignStart">
            <input
              name="marketing_in"
              id="marketing_in"
              type="checkbox"
              v-model="checkPrivacy"
            />
            <div for="marketing_in" class="marketingCheckBox">
              <span id="marketing_lbl" v-html="this.gigyaMarketing"></span>
            </div>
          </div>

          <div class="panelLoginRow mb10">
            <div
              id="btn_confirm"
              class="panelLoginRow buttonGigya mt20 pointer disabled"
            >
              {{ gigyaCrea }}
            </div>
          </div>

          <div class="mt20">
            <img id="curr_flag" :src="urlFlag" />
            <div class="invisibleGigyaLang">
              <select
                id="lang_id_in"
                name="flag"
                v-model="locale"
                @change="changeLocale()"
                class="flagSelector"
              >
                <option value="IT">{{ labelIT }}</option>
                <option value="EN">{{ labelEN }}</option>
                <option value="ES">{{ labelES }}</option>
                <option value="PT">{{ labelPT }}</option>
                <option value="TR">{{ labelTR }}</option>
                <option value="FR">{{ labelFR }}</option>
              </select>
            </div>

            <div id="logo_horta" class="lastDiv"></div>

            <div id="terminiservizio" @click="terminiServizio()"></div>
            <div id="marketingPrivacy" @click="marketingPrivacy()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import queries from "../mixins/queries.js";
import locales from "../mixins/locales.js";
import cache from "../mixins/cache.js";

import compTermini from "./Termini.vue";
import compMarketing from "./Marketing.vue";

export default {
  data() {
    return {
      nome: null,
      cognome: null,
      mail: null,
      cellulare: null,
      password: null,
      cPassword: null,
      nazione: null,
      timer: null,
      showTermini: false,
      showMarketing: false,
      checkTermini: false,
      checkPrivacy: false,
      numPassChars: 10,
      locale: "IT",
      urlFlag:
        "https://cloud.horta-srl.it/horta_menu/resources/images/flags/it_w.png",
      inputType: "password",
      eyeIcon: "eye",
      inputTypeRegistration: "password",
      eyeIconRegistration: "eye"
    };
  },
  components: { compTermini, compMarketing },
  mixins: [queries, locales, cache],
  computed: {
    ...mapGetters({
      token: "token",
      role: "role",
      reteID: "reteID",
      globalLocale: "globalLocale",
      acceptDisclaimer: "acceptDisclaimer"
    })
  },
  methods: {
    ...mapMutations([
      "setToken",
      "setPassword",
      "setUsername",
      "setCompToRender",
      "setShowNoAziende",
      "setShowNoUp",
      "setItemInfo",
      "setRole",
      "setReteID",
      "setGlobalLocale",
      "setAcceptDisclaimer"
    ]),
    getLabel(key) {
      return this.strings[this.globalLocale][key];
    },
    changeLocale() {
      this.setGlobalLocale(this.locale);
      this.urlFlag =
        "https://cloud.horta-srl.it/horta_menu/resources/images/flags/" +
        this.locale.toLowerCase() +
        "_w.png";
      document.title = this.headerTitle;
    },
    changeStatus() {
      let chk_terms = document.getElementById("terms_in");
      let btn_confirm = document.getElementById("btn_confirm");
      if (chk_terms.checked) {
        btn_confirm.classList.remove("disabled");
        btn_confirm.addEventListener("click", this.performReg);
      } else {
        btn_confirm.classList.add("disabled");
        btn_confirm.removeEventListener("click", this.performReg);
      }
    },
    showPassword() {
      console.log("showPassword");
      if (this.eyeIcon == "eye") {
        this.eyeIcon = "eye-slash";
        this.inputType = "text";
      } else {
        this.eyeIcon = "eye";
        this.inputType = "password";
      }
    },
    showConfirmPassword() {
      if (this.eyeIconRegistration == "eye") {
        this.eyeIconRegistration = "eye-slash";
        this.inputTypeRegistration = "text";
      } else {
        this.eyeIconRegistration = "eye";
        this.inputTypeRegistration = "password";
      }
    },
    checkMail() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.callCheckMail(), 500);
    },
    checkPassword() {
      console.log("checkPassword");
      clearTimeout(this.timer);
      this.timer = setTimeout(this.callCheckPassword(), 500);
    },
    checkMailResponse(response) {
      if (response.isAvailable) {
        document.getElementById("valid_email").className = "bold green";
      } else {
        document.getElementById("email_in_use").className = "bold red";
      }
    },
    callCheckMail() {
      document.getElementById("valid_email").className = "hidden";
      document.getElementById("invalid_email").className = "hidden";
      document.getElementById("email_in_use").className = "hidden";

      var validRegex = /^[a-zA-Z0-9.!#$%&amp;'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

      if (this.mail.match(validRegex)) {
        const params = {
          callback: this.checkMailResponse,
          loginID: this.mail
        };
        // eslint-disable-next-line
        gigya.accounts.isAvailableLoginID(params);
      } else {
        document.getElementById("invalid_email").className = "bold red";
      }
    },
    callCheckPassword() {
      document.getElementById("invalid_pwd").className = "hidden";

      var validRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-+_!@#$%^&*.,?]).{10,}$/gm;

      if (!this.password.match(validRegex)) {
        document.getElementById("invalid_pwd").className = "bold red";
      }
    },
    performReg() {
      if (!this.checkData()) {
        return;
      }

      const xhr = new XMLHttpRequest();
      xhr.open("POST", "/horta_ciam_auth/api/v1/accounts");
      xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader(
        "Access-Control-Allow-Methods",
        "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      );
      const body = JSON.stringify({
        emailAddress: this.mail,
        password: this.password,
        firstName: this.nome,
        lastName: this.cognome,
        mobile: this.cellulare,
        country: this.nazione,
        lang: this.locale,
        terms: this.checkTermini,
        marketing: this.checkPrivacy
      });
      xhr.onload = async () => {
        if (xhr.readyState == 4 && xhr.status >= 200 && xhr.status < 300) {
          this.showMessage(
            this.getLabel("gigya_register_check_mail"),
            "success"
          );
          await this.delay(3000);
          document.location.href = "gigya_login.xhtml";
        } else {
          this.showMessage(this.getLabel("generic_error"), "danger");
        }
      };
      xhr.send(body);
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    checkData() {
      let errs = 0;
      let errMsg = "";

      if (!this.nome) {
        errMsg += this.getLabel("gigya_register_empty_name") + "<br>";
        errs = errs + 1;
      }

      if (!this.cognome) {
        errMsg += this.getLabel("gigya_register_empty_surname") + "<br>";
        errs = errs + 1;
      }

      if (!this.mail) {
        errMsg += this.getLabel("gigya_register_empty_mail") + "<br>";
        errs = errs + 1;
      }

      if (
        !document.getElementById("invalid_email").classList.contains("hidden")
      ) {
        errMsg += this.getLabel("gigya_invalid_email") + "<br>";
        errs = errs + 1;
      }

      if (
        !document.getElementById("email_in_use").classList.contains("hidden")
      ) {
        errMsg += this.getLabel("gigya_email_in_use") + "<br>";
        errs = errs + 1;
      }

      if (
        !document.getElementById("invalid_pwd").classList.contains("hidden")
      ) {
        errMsg += this.getLabel("gigya_invalid_password") + "<br>";
        errs = errs + 1;
      }

      if (!this.password) {
        errMsg += this.getLabel("gigya_register_empty_password") + "<br>";
        errs = errs + 1;
      }

      /*if (this.password.length < this.numPassChars) {
        errMsg +=
          this.getLabel("gigya_new_pwd_length") + this.numPassChars + "<br>";
        errs = errs + 1;
      }*/

      if (this.password != this.cPassword) {
        errMsg += this.getLabel("gigya_new_pwd_match") + "<br>";
        errs = errs + 1;
      }

      if (errs > 0) {
        this.showMessage(errMsg, "warning");
        return false;
      } else {
        return true;
      }
    },
    showMessage(message_text, message_type) {
      let messageTarget = document.getElementById("logo_horta");
      if (message_text == "") {
        messageTarget.innerHTML = "";
      } else {
        messageTarget.innerHTML =
          '<div class="alert alert-' +
          message_type +
          '">' +
          message_text +
          "</div>";
      }
    },
    marketingPrivacy() {
      this.showMarketing = true;
    },
    chiudiMarketingPrivacy() {
      this.showMarketing = false;
    },
    terminiServizio() {
      this.showTermini = true;
    },
    chiudiTerminiServizio() {
      this.showTermini = false;
    }
  },
  mounted() {
    this.locale = this.globalLocale.toLowerCase();
    this.urlFlag =
      "https://cloud.horta-srl.it/resources/images/flags/" +
      this.locale +
      "_w.png";

    const script = document.createElement("script");
    script.innerHTML =
      'function terminiServizio(){ document.getElementById("terminiservizio").click(); }';

    document.head.appendChild(script);

    const script2 = document.createElement("script");
    script2.innerHTML =
      'function marketingPrivacy(){ document.getElementById("marketingPrivacy").click(); }';

    document.head.appendChild(script2);
  }
};
</script>

<style scoped>
@import "../assets/styles/gigya.css";

.red {
  color: #960000;
}

.green {
  color: #006900;
}
</style>
