import axios from "axios";
import { store } from "../Store/store.js";

let urlAgriGo = store.state.urlAgriGo;
let urlJWTPass = store.state.urlJWTPass;
let urlCiamAuth = store.state.urlCiamAuth;
//let endpoint = "/api/v1/services/FITOSPA15AGEGO?svr_id_in=cloud";

const hostname = window.location.hostname;
if (hostname.indexOf("horta-stg") > -1) {
  if (hostname.indexOf("test.") > -1) {
    urlAgriGo = "https://agrigeniusgotest.horta-stg.basf.com/api";
    urlJWTPass = "https://agrigeniusgotest.horta-stg.basf.com/pass";
  } else {
    urlAgriGo = "https://agrigeniusgo.horta-stg.basf.com/api";
    urlJWTPass = "https://agrigeniusgo.horta-stg.basf.com/pass";
  }
}

export default {
  methods: {
    retrieveHtml(url, token) {
      return axios({
        url: url,
        method: "get",
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    },
    executeQuery(token, queryString) {
      return axios({
        url: urlAgriGo,
        method: "post",
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          query: queryString
        }
      });
    },
    executeQueryChart(token, queryString) {
      return axios({
        url: urlAgriGo,
        method: "post",
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          query: queryString
        }
      });
    },
    executeQueryGigya(token, endpoint) {
      return axios({
        url: urlCiamAuth + endpoint,
        method: "get",
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {}
      });
    },
    retrieveToken(userName, userPwd) {
      let q = `query {
        htoken(
          UserID: "${userName}",
          Password: "${userPwd}",
          Lang: "IT")
          {
            Issuer
            Bearer
            IssuedAt
            ExpiresIn
          }
        }`;

      return axios({
        url: urlJWTPass,
        method: "post",
        data: {
          query: q
        }
      });
    },
    retrieveAuthKey(token) {
      let q = `query {
          authKeys {
              ID
              Type
              Value
          }
      }`;
      return this.executeQuery(token, q);
    },
    chiaviServizi(token) {
      const endpoint = `/api/v1/services/${store.state.servIdIn}?svr_id_in=cloud`;
      return this.executeQueryGigya(token, endpoint);
    },
    retrieveUp(token) {
      let q = `query {
        listaUP(orderBy: "UPID") {
          UPID
          IDCli
          pIVAAzi
          UPDes
          nazISO
          proIst
          comIst
          regIst
          nuts2Code
          nuts3Code
          quadranteID
          latitudine
          longitudine
          sistemaColturaleID
          colCod
          varCod
        }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveUpInfo(
      token,
      lang,
      idCli,
      pIvaAzi,
      nazIso,
      regIst,
      proIst,
      comIst,
      quadranteID,
      siscoltId,
      colCod,
      varCod
    ) {
      let q = `
        query {
          formNuovaUP {
              listaUtenti(IDCli: "${idCli}") {
                  IDCli
                  pIVA
                  rgsCliente
              }
              listaAziende(IDCli: "${idCli}", pIVAAzi: "${pIvaAzi}") {
                  IDCli
                  pIVAAzi
                  rgsAzienda
              }
              listaNazioni(nazISO: "${nazIso}") {
                  nazISO
                  nazDes
              }
              listaRegioni(regIst: "${regIst}") {
                  regioni {
                      regIst
                      regDes
                  }
                  nutsLevel2 {
                      nuts2Code
                      nuts2Des
                  }
              }
              listaProvince(regIst: "${regIst}", proIst: "${proIst}") {
                  province {
                      proIst
                      regIst
                      proDes
                  }
                  nutsLevel3 {
                      nuts3Code
                      nuts3Des
                      nuts2Code
                  }
              }
              listaComuni(proIst: "${proIst}", comIst: "${comIst}") {
                  proIst
                  comIst
                  comDes
              }
              listaStazioni(quadranteID: "${quadranteID}") {
                  regIst
                  tipQuadranteID
                  quadranteID
                  quadranteNome
                  quadranteDes
              }
              listaSistemiColturali(langID: "${lang}", sistemaColturaleID: "${siscoltId}") {
                  sistemaColturaleID
                  sistemaColturaleDes
              }
              listaVarieta(colCod: "${colCod}", varCod: "${varCod}") {
                  varCod
                  varDes
              }
          }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveUpDropDownInfo(
      token,
      lang,
      colCod,
      nazIso,
      orderByReg,
      regIst,
      nuts2_code,
      orderByProv,
      proIst,
      reteId
    ) {
      let q = `query {
        formNuovaUP {
          listaUtenti(orderBy: "rgsCliente") {
            IDCli
            rgsCliente
          }
          listaAziende(orderBy: "rgsAzienda") {
            rgsAzienda
            IDCli
            pIVAAzi
            nazISO
            proIst
            comIst
            nuts2Code
            nuts3Code
          }
          listaNazioni(orderBy: "nazDes") {
            nazISO
            nazDes
            nazAvailable
          }
          listaRegioni(nazISO: "${nazIso}", orderBy: "${orderByReg}") {
            regioni {
                regIst
                regDes
            }
            nutsLevel2 {
                nuts2Code
                nuts2Des
            }
          }
          listaProvince(regIst: "${regIst}", nuts2Code: "${nuts2_code}", orderBy: "${orderByProv}") {
              province {
                  proDes
                  proIst
              },
              nutsLevel3 {
                  nuts3Des
                  nuts3Code
              }
          }
          listaComuni(proIst: "${proIst}", orderBy: "comDes") {
            proIst
            comIst
            comDes
            lat
            lng
          }
          listaStazioni(reteID: "${reteId}", orderBy: "quadranteNome") {
            quadranteID
            quadranteNome
            quadranteDes
            latitudineGradi
            latitudineMin
            latitudineSec
            longitudineGradi
            longitudineMin
            longitudineSec
          }
          listaSistemiColturali(langID: "${lang}", orderBy: "sistemaColturaleDes") {
            sistemaColturaleID
            sistemaColturaleDes
          }
          listaVarieta(colCod: "${colCod}", orderBy: "varDes") {
            varCod
            varDes
          }
          livelloPressioneBotrite(langID: "${lang}", orderBy: "livelloPressioneSeqno") {
            livelloPressioneID
            livelloPressioneDes
            livelloPressioneSeqno
          }
          dosiInoculoPeronospora(langID: "${lang}", orderBy: "doseInoculoSeqno") {
            doseInoculoID
            doseInoculoDes
          }
        }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveUtenti(token) {
      let q = `query {
          formNuovaUP {
            listaUtenti(orderBy: "rgsCliente") {
              rgsCliente
              IDCli
              pIVA
            }
          }
        }`;
      return this.executeQuery(token, q);
    },
    retrieveAziende(token, idCli) {
      let q = `query {
          formNuovaUP {
            listaAziende(IDCli: "${idCli}", orderBy: "rgsAzienda") {
              rgsAzienda
              IDCli
              pIVAAzi
              nazISO
              proIst
              comIst
              nuts2Code
              nuts3Code
            }
          }
        }`;
      return this.executeQuery(token, q);
    },
    retrieveAziendaInfo(token, idCli, nazIso, regIst, proIst, comIst) {
      let q = `
        query {
            formNuovaAzienda {
                listaUtenti(IDCli: "${idCli}") {
                    IDCli
                    pIVA
                    rgsClienti
                }
                listaNazioni(nazISO: "${nazIso}") {
                    nazISO
                    nazDes
                }
                listaRegioni(regIst: "${regIst}") {
                    regioni {
                        regIst
                        regDes
                    }
                    nutsLevel2 {
                        nuts2Code
                        nuts2Des
                    }
                }
                listaProvince(regIst: "${regIst}", proIst: "${proIst}") {
                    province {
                        proIst
                        regIst
                        proDes
                    }
                    nutsLevel3 {
                        nuts3Code
                        nuts3Des
                        nuts2Code
                    }
                }
                listaComuni(proIst: "${proIst}", comIst: "${comIst}") {
                    proIst
                    comIst
                    comDes
                }
            }
        }`;
      return this.executeQuery(token, q);
    },
    retrieveAziendeDropDownInfo(
      token,
      nazIso,
      orderByReg,
      regIst,
      nuts2Code,
      orderByProv,
      proIst
    ) {
      let q = `query {
        formNuovaAzienda {
          listaUtenti(orderBy: "rgsCliente") {
              IDCli
              rgsCliente
          }
          listaNazioni(orderBy: "nazDes") {
              nazISO
              nazDes
              nazAvailable
          }
          listaRegioni(nazISO: "${nazIso}", orderBy: "${orderByReg}") {
            regioni {
                regIst
                regDes
            }
            nutsLevel2 {
                nuts2Code
                nuts2Des
            }
          }
          listaProvince(regIst: "${regIst}", nuts2Code: "${nuts2Code}", orderBy: "${orderByProv}") {
              province {
                  proDes
                  proIst
                  regIst
              },
              nutsLevel3 {
                  nuts3Des
                  nuts3Code
              }
          }
          listaComuni(proIst: "${proIst}", orderBy: "comDes") {
            proIst
            comIst
            comDes
            lat
            lng
          }
        }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveNazioni(token) {
      let q = `query {
        formNuovaUP {
          listaNazioni(orderBy: "nazDes") {
            nazISO
            nazDes
            nazAvailable
          }
        }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveRegioni(token, nazIso, orderBy) {
      let q = `query {
        formNuovaUP {
          listaRegioni(nazISO: "${nazIso}", orderBy: "${orderBy}") {
            regioni {
                regIst
                regDes
            }
            nutsLevel2 {
                nuts2Code
                nuts2Des
            }
          }
        }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveProvince(token, regIst, nuts2Code, orderBy) {
      let q = `query {
        formNuovaUP {
          listaProvince(regIst: "${regIst}", nuts2Code: "${nuts2Code}", orderBy: "${orderBy}") {
              province {
                  proDes
                  proIst
                  regIst
              },
              nutsLevel3 {
                  nuts3Des
                  nuts3Code
                  nuts2Code
              }
          }
        }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveComuni(token, proIst, comIst) {
      let q = `query {
          formNuovaUP {
            listaComuni(proIst: "${proIst}", comIst: "${comIst}", orderBy: "comDes") {
              proIst
              comIst
              comDes
              lat
              lng
            }
          }
        }`;
      return this.executeQuery(token, q);
    },
    retrieveStazioni(token, reteId) {
      let q = `query {
        formNuovaUP {
          listaStazioni(reteID: "${reteId}", orderBy: "quadranteNome") {
            regIst
            tipQuadranteID
            quadranteID
            quadranteNome
            quadranteDes
          }
        }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveCoordinateStazione(token, qId) {
      let q = `query {
        formNuovaUP {
          listaStazioni(quadranteID: "${qId}") {
            regIst
            tipQuadranteID
            quadranteID
            quadranteNome
            quadranteDes
            latitudineGradi
            latitudineMin
            latitudineSec
            longitudineGradi
            longitudineMin
            longitudineSec
          }
        }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveSistColt(token, lang) {
      let q = `query {
        formNuovaUP {
          listaSistemiColturali(langID: "${lang}", orderBy: "sistemaColturaleDes") {
            sistemaColturaleID
            sistemaColturaleDes
          }
        }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveVarieta(token, col_cod) {
      let q = `query {
          formNuovaUP {
            listaVarieta(colCod: "${col_cod}", orderBy: "varDes") {
              varCod
              varDes
            }
          }
        }`;
      return this.executeQuery(token, q);
    },
    retrieveInoculo(token, lang) {
      let q = `query {
          formNuovaUP {
            dosiInoculoPeronospora(langID: "${lang}", orderBy: "doseInoculoSeqno") {
              doseInoculoID
              doseInoculoDes
            }
          }
        }`;
      return this.executeQuery(token, q);
    },
    retrievePressione(token, lang) {
      let q = `query {
          formNuovaUP {
            livelloPressioneBotrite(langID: "${lang}", orderBy: "livelloPressioneDes") {
              livelloPressioneID
              livelloPressioneDes
              livelloPressioneSeqno
            }
          }
        }`;
      return this.executeQuery(token, q);
    },
    retrieveFasiFen(token, langId, upId) {
      let q = `query {
          fasiFenologiche(
              UPID: "${upId}"
              langID: "${langId}"
          ){
              IDFase
              descrizione
              dataFaseModello
              dataFaseUtente
              annoRiferimento
          }
      }`;
      return this.executeQuery(token, q);
    },
    mutationInsertUp(
      token,
      idCli,
      upSup,
      pivaazi,
      updes,
      naziso,
      regist,
      proist,
      comist,
      nuts2,
      nuts3,
      colcod,
      varcod,
      qId,
      regIstQ,
      tipQId,
      lat,
      lng,
      sisColtId,
      lvlcautela,
      presenzabr,
      lvlbotrite
    ) {
      let m = `mutation {
        creaNuovaUP(
          IDCli: "${idCli}",
          pIVAAzi: "${pivaazi}",
          UPSup: "${upSup}",
          UPDes: "${updes}",
          nazISO: "${naziso}",
          regIst: "${regist}",
          proIst: "${proist}",
          comIst: "${comist}",
          nuts2Code: "${nuts2}",
          nuts3Code: "${nuts3}",
          quadranteID: "${qId}",
          regIstQuadrante: "${regIstQ}",
          tipQuadranteID: "${tipQId}",
          colCod: "${colcod}",
          varCod: "${varcod}",
          latitudine: "${lat}",
          longitudine: "${lng}",
          sistemaColturaleID: "${sisColtId}",
          doseInoculoPeronosporaID: "${lvlcautela}",
          presenzaMarciumeNero: "${presenzabr}",
          livelloPressioneBotriteID: "${lvlbotrite}",
          freezed: "1"
          ) {
          UPID
          IDCli
          UPDes
          colCod
          varCod
          latitudine
          longitudine
          }
      }`;
      return this.executeQuery(token, m);
    },
    retrieveMasterUp(token, lang, active) {
      let q = `query {
        masterUP(
          UPDes: "",
          UPSup: "",
          proIst: "",
          comIst: "",
          quadranteID: "",
          IDCli: "",
          pIVAAzi: "",
          nazISO: "",
          regIst: "",
          sistemaColturaleID: "",
          varCod: "",
          nuts2Code: "",
          nuts3Code: "",
          active: "${active}"
          langID: "${lang}"
          orderBy: "UPDes") {
            UPID
            UPDes
            UPSup
            IDCli
            rgsCliente
            pIVAAzi
            rgsAzienda
            nazISO
            nazDes
            regIst
            regDes
            nuts2Code
            nuts2Des
            proIst
            proDes
            nuts3Code
            nuts3Des
            comIst
            comDes
            latitudine
            longitudine
            quadranteID
            quadranteDes
            quadranteNome
            sistemaColturaleID
            sistemaColturaleDes
            varCod
            varDes
            doseInoculoPeronosporaID
            doseInoculoPeronosporaDes
            annoRiferimentoAvversita
            presenzaMarciumeNero
            freezed
            dataExpired
            livelloPressioneBotriteID
            livelloPressioneBotriteDes
          }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveMasterUpFiltred(token, example, active, lang) {
      let r = "";
      let n2 = "";
      let p = "";
      let n3 = "";
      if (example.nazISO == "IT") {
        r = example.regione || "";
      } else {
        n2 = example.regione || "";
      }
      if (example.regione != "") {
        p = example.provincia || "";
      } else {
        n3 = example.provincia || "";
      }

      let q = `query {
        masterUP(
          UPDes: "${example.UPDes}",
          proIst: "${p}",
          comIst: "${example.comIst}",
          quadranteID: "${example.quadranteID}",
          IDCli: "${example.IDCli}",
          pIVAAzi: "${example.pIVAAzi}",
          nazISO: "${example.nazISO}",
          regIst: "${r}",
          sistemaColturaleID: "${example.sistemaColturaleID}",
          varCod: "${example.varCod}",
          nuts2Code: "${n2}",
          nuts3Code: "${n3}",
          active: "${active}"
          langID: "${lang}"
          orderBy: "UPDes") {
            UPID
            UPDes
            UPSup
            IDCli
            rgsCliente
            pIVAAzi
            rgsAzienda
            nazISO
            nazDes
            regIst
            regDes
            nuts2Code
            nuts2Des
            proIst
            proDes
            nuts3Code
            nuts3Des
            comIst
            comDes
            latitudine
            longitudine
            quadranteID
            quadranteDes
            quadranteNome
            sistemaColturaleID
            sistemaColturaleDes
            varCod
            varDes
            doseInoculoPeronosporaID
            doseInoculoPeronosporaDes
            annoRiferimentoAvversita
            presenzaMarciumeNero
            freezed
            dataExpired
            livelloPressioneBotriteID
            livelloPressioneBotriteDes
          }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveMasterAziende(token) {
      let q = `query {
          masterAziende(
            rgs: "",
            pIVAAzi: "",
            IDCli: "",
            nazISO: "",
            proIst: "",
            comIst: "",
            orderBy: "rgs") {
              IDCli
              rgsCliente
              pIVAAzi
              rgsAzienda
              nazISO
              nazDes
              proIst
              proDes
              comIst
              comDes
              nuts2Code
              nuts2Des
              nuts3Code
              nuts3Des
              indirizzo
              CAP
              eMail
              codiceFiscale
        }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveMasterAziendeFiltred(token, example) {
      let q = `query {
           masterAziende(
              rgs: "${example.rgsAzienda}",
              pIVAAzi: "${example.pIVAAzi}",
              IDCli: "${example.IDCli}",
              nazISO: "${example.nazISO}",
              proIst: "${example.proIst}",
              comIst: "${example.comIst}",
              orderBy: "rgs") {
                IDCli
                rgsCliente
                pIVAAzi
                rgsAzienda
                nazISO
                nazDes
                proIst
                proDes
                comIst
                comDes
                nuts2Code
                nuts2Des
                nuts3Code
                nuts3Des
                indirizzo
                CAP
                eMail
                codiceFiscale
          }
        }`;
      return this.executeQuery(token, q);
    },
    retrieveMasterProdottiPreferitiAzienda(token, langID, idCliAzi, pIvaAzi) {
      let q = `query {
        masterProdottiPreferiti(
        pIVAAzi: "${pIvaAzi}",
        IDCli: "${idCliAzi}",
        langID: "${langID}"
        orderBy: "nome") {
            prodottoID
            IDCliAzi
            pIVAAzi
            nome
            distributoreID
            distributoreDes
            avversitaDes
        }
      }`;
      return this.executeQuery(token, q);
    },
    retrieveMasterProdotti(token, mercatoID) {
      let q = `query {
          masterProdotti(
          prodottoID: "",
          nome: "",
          pIVA: "",
          rgs: "",
          mercatoID: "${mercatoID}",
          orderBy: "nome"
          ) {
          prodottoID
          nome
          distributoreDes
          distributoreID
          revocato
          mercatoID
        }
      }`;
      return this.executeQuery(token, q);
    },
    mutationUpdateUp(
      token,
      upId,
      upSup,
      idCli,
      pivaazi,
      updes,
      naziso,
      regist,
      proist,
      comist,
      nuts2,
      nuts3,
      colcod,
      varcod,
      qId,
      lat,
      lng,
      sisColtId,
      lvlcautela,
      presenzabr,
      lvlbotrite
    ) {
      let m = `mutation {
        updateUP(
          UPID: "${upId}",
          IDCli: "${idCli}",
          UPSup: "${upSup}",
          pIVAAzi: "${pivaazi}",
          UPDes: "${updes}",
          nazISO: "${naziso}",
          regIst: "${regist}",
          proIst: "${proist}",
          comIst: "${comist}",
          nuts2Code: "${nuts2}",
          nuts3Code: "${nuts3}",
          quadranteID: "${qId}",
          colCod: "${colcod}",
          varCod: "${varcod}",
          latitudine: "${lat}",
          longitudine: "${lng}",
          sistemaColturaleID: "${sisColtId}",
          doseInoculoPeronosporaID: "${lvlcautela}",
          presenzaMarciumeNero: "${presenzabr}",
          livelloPressioneBotriteID: "${lvlbotrite}"
          ) {
          UPID
          }
      }`;
      return this.executeQuery(token, m);
    },
    mutationDuplicaUp(token, upId, latitudine) {
      let m = `mutation {
        duplicaUP(
            UPID: "${upId}"
            latitudine: "${latitudine}",
            ) {
            UPID
          }
      }`;
      return this.executeQuery(token, m);
    },
    mutationConfirmUp(token, upId, latitudine) {
      let m = `mutation {
        confermaUP(
            UPID: "${upId}"
            latitudine: "${latitudine}",
            ) {
            UPID
            freezed
            cancellatori
            IDCli
            UPDes
            UPSup
            colCod
            latitudine
            longitudine
            dataExpired
          }
      }`;
      return this.executeQuery(token, m);
    },
    mutationInsertAzienda(
      token,
      idCli,
      rgs,
      pivaazi,
      codfis,
      naziso,
      regist,
      proist,
      comist,
      nuts2,
      nuts3,
      indirizzo,
      cap,
      email
    ) {
      let m = `mutation {
        creaNuovaAzienda (
          IDCli: "${idCli}",
          ragioneSociale: "${rgs}",
          pIVA: "${pivaazi}",
          codiceFiscale: "${codfis}",
          nazISO: "${naziso}",
          regIst: "${regist}",
          proIst: "${proist}",
          comIst: "${comist}",
          nuts2Code: "${nuts2}",
          nuts3Code: "${nuts3}",
          indirizzo: "${indirizzo}",
          CAP: "${cap}",
          eMail: "${email}"
          ) {
            IDCli
            rgsCli
            pIVAAzi
            rgsAzienda
            nazISO
            nazDes
            regIst
            regDes
            proIst
            proDes
            comIst
            comDes
            indirizzo
            CAP
            eMail
            codiceFiscale
          }
      }`;
      return this.executeQuery(token, m);
    },
    mutationUpdateAzienda(
      token,
      idCli,
      rgs,
      pivaazi,
      codfis,
      naziso,
      regist,
      proist,
      comist,
      nuts2,
      nuts3,
      indirizzo,
      cap,
      email
    ) {
      let m = `mutation {
        updateAzienda (
          IDCli: "${idCli}",
          ragioneSociale: "${rgs}",
          pIVA: "${pivaazi}",
          codiceFiscale: "${codfis}",
          nazISO: "${naziso}",
          regIst: "${regist}",
          proIst: "${proist}",
          comIst: "${comist}",
          nuts2Code: "${nuts2}",
          nuts3Code: "${nuts3}",
          indirizzo: "${indirizzo}",
          CAP: "${cap}",
          eMail: "${email}"
          ) {
            IDCli
            rgsCli
            pIVAAzi
            rgsAzienda
            nazISO
            nazDes
            regIst
            regDes
            proIst
            proDes
            comIst
            comDes
            indirizzo
            CAP
            eMail
            codiceFiscale
          }
      }`;
      return this.executeQuery(token, m);
    },
    mutationUpdateFasiFen(token, upId, idFase, annoRif, faseUtente) {
      let m = `mutation {
        updateFasiFenologiche(
            UPID: "${upId}"
            IDFase: "${idFase}"
            IDSpecie: "05",
            annoRiferimento: "${annoRif}"
            dataFaseUtente: "${faseUtente}"
        ) {
            IDSpecie
            IDFase
            descrizione
            seqno
            dataFaseModello
            dataFaseUtente
            annoRiferimento
        }
      }`;
      return this.executeQuery(token, m);
    },
    mutationInsertProdottoPreferito(token, prodottoId, idCli, pIvaAzi) {
      let m = `mutation {
        creaNuovoProdottoPreferito(
            prodottoID: "${prodottoId}"
            IDCli: "${idCli}"
            pIVAAzi: "${pIvaAzi}"
        ) {
            prodottoID
            IDCli
            pIVAAzi
        }
      }`;
      return this.executeQuery(token, m);
    },
    mutationDeleteProdottoPreferito(token, prodottoId, idCli, pIvaAzi) {
      let m = `mutation {
        deleteProdottoPreferito(
            prodottoID: "${prodottoId}"
            IDCli: "${idCli}"
            pIVAAzi: "${pIvaAzi}"
        ) {
            prodottoID
            IDCli
            pIVAAzi
        }
      }`;
      return this.executeQuery(token, m);
    },
    mutationDeleteAzienda(token, idCli, pIvaAzi) {
      let m = `mutation {
        deleteAzienda(
            IDCli: "${idCli}"
            pIVAAzi: "${pIvaAzi}"
        ) {
            IDCli
            pIVAAzi
            rgsAzienda
        }
      }`;
      return this.executeQuery(token, m);
    },
    mutationDeleteUP(token, upId) {
      let m = `mutation {
        deleteUP(
            UPID: "${upId}"
        ) {
            UPID
            IDCli
            UPDes
        }
      }`;
      return this.executeQuery(token, m);
    },
    queryChart(
      token,
      type,
      subtype,
      upId,
      legacy,
      layout,
      pe,
      oi,
      bot,
      br,
      feno,
      prot,
      style,
      avversita
    ) {
      let c = `query{
        grafici (
          type: "${type}"
          subType: "${subtype}"
          UPID: "${upId}"
          legacy: "${legacy}"
          layout: "${layout}"
          peronospora: "${pe}"
          oidio: "${oi}"
          botrite: "${bot}"
          blackrot: "${br}"
          fenologia: "${feno}"
          protezione: "${prot}"
          style: "${style}"
          IDAvversitaIn: "${avversita}"
        ) {
          endpoint
          result
        }
      }`;
      return this.executeQueryChart(token, c);
    }
  }
};
